import React, { FC, useMemo, useRef, useCallback } from 'react';
import clsx from 'clsx';
import { useLocation } from '@reach/router';
import styles from './nav-item.module.css';

import { useIsHover } from '../../../../hooks/use-is-hover';

import { Link } from '../../../atoms/link';
import { Text } from '../../../atoms/text/text';
import { CaretIcon } from '../../../molecules/icons';
import { SanityNavigationItem } from 'graphql-types';

interface NavItemProps {
  route: SanityNavigationItem;
  opened?: boolean;
  onComplexRouteClick?: (route: SanityNavigationItem) => void;
  onNavigate?: () => void;
}

const complexRouteActive = (
  currentPath: string,
  route: SanityNavigationItem
) => {
  return Boolean(route.childrenItems.find(route => route.path === currentPath));
};

const baseRouteActive = (currentPath: string, route: SanityNavigationItem) => {
  return currentPath === route.path;
};

export const NavItem: FC<NavItemProps> = props => {
  const { route, onComplexRouteClick, onNavigate, opened = false } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { isHover } = useIsHover({ element: ref });
  const { pathname: currentPath } = useLocation();

  const hasChildrenRoutes = useMemo(() => {
    return (route as SanityNavigationItem).childrenItems?.length > 0;
  }, [route]);

  const activeLinkClassName =
    'underline underspace underline-clover underline-offset-xtiny';

  const hoverInactiveLinkClassName =
    'underline underspace underline-light-clover underline-offset-xtiny';

  const routeActive = useMemo(() => {
    return hasChildrenRoutes
      ? complexRouteActive(currentPath, route as SanityNavigationItem)
      : baseRouteActive(currentPath, route as SanityNavigationItem);
  }, [currentPath, hasChildrenRoutes, route]);

  const handleOpenSideMenu = useCallback(() => {
    onComplexRouteClick(route);
  }, [route, onComplexRouteClick]);

  return (
    <div className="relative" ref={ref}>
      {hasChildrenRoutes ? (
        <Text
          variant="u1a"
          className={clsx(
            'pointer',
            'inline-flex-center',
            { [activeLinkClassName]: routeActive },
            styles.navItem,
            { [hoverInactiveLinkClassName]: !routeActive && isHover }
          )}
          aria-label={`Open subroutes for "${route.title}""`}
          role="button"
          onClick={handleOpenSideMenu}
        >
          {route.title}
          <CaretIcon
            className={clsx('ml-xtiny', styles.navItemCaret)}
            iconWidth="16"
            direction={opened ? 'up' : 'down'}
            strokeWidth={2.5}
          />
        </Text>
      ) : (
        <Link
          variant="u1a"
          aria-label={`Navigate to ${route.title}`}
          destination={(route as SanityNavigationItem)?.path}
          onClick={onNavigate}
          activeClassName={activeLinkClassName}
          className={clsx(styles.navItem, {
            [hoverInactiveLinkClassName]: !routeActive && isHover
          })}
          forceHardNavigation={true}
        >
          {route.title}
        </Link>
      )}
    </div>
  );
};
