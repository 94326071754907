import React, { FC, useMemo } from 'react';

import { Link } from '../../../atoms/link';
import { MobileNavSubmenu } from '../mobile-nav-submenu/mobile-nav-submenu';
import { SanityNavigationItem } from 'graphql-types';
import { fontWeight } from 'styled-system';

const variants = ['nav', 'h2a'] as const;

interface MobileNavItemProps {
  route: SanityNavigationItem;
  variant?: typeof variants[number];
  onNavigate?: () => void;
}

export const MobileNavItem: FC<MobileNavItemProps> = props => {
  const { route, variant = 'nav', onNavigate } = props;

  const hasChildrenRoutes = useMemo(() => {
    return (route as SanityNavigationItem).childrenItems?.length > 0;
  }, [route]);

  return hasChildrenRoutes ? (
    <MobileNavSubmenu
      route={route as SanityNavigationItem}
      onNavigate={onNavigate}
    />
  ) : (
    <Link
      color="darkGreen"
      variant={variant}
      aria-label={`Navigate to ${route.title}`}
      destination={(route as SanityNavigationItem)?.path}
      onClick={onNavigate}
      style={{ fontWeight: 500 }}
      forceHardNavigation={true}
    >
      {route.title}
    </Link>
  );
};
