import React, { FC, useState } from 'react';
import styles from './mobile-nav-submenu.module.css';
import clsx from 'clsx';

import { Link } from '../../../atoms/link';
import { Text } from '../../../atoms/text/text';
import { CaretIcon } from '../../../molecules/icons';
import useWindowSize from '../../../../hooks/use-window-size';
import { breakpoint } from '../../../types/index';
import { SanityNavigationItem } from 'graphql-types';

interface MobileNavItemProps {
  route: SanityNavigationItem;
  onNavigate?: () => void;
}

export const MobileNavSubmenu: FC<MobileNavItemProps> = props => {
  const { route, onNavigate } = props;
  const [isOpened, setIsOpened] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < breakpoint;
  const submenuCount = route.childrenItems.length + 1;
  const submenuHeight = submenuCount * (isMobile ? 48 : 54);

  const handleNavigate = () => {
    onNavigate();
    setIsOpened(false);
  };

  return (
    <div>
      <Text
        color="dark-green"
        variant="h2a"
        className={`pointer inline-flex-center`}
        aria-label={`Open subroutes for "${route.title}""`}
        role="button"
        onClick={() => setIsOpened(prev => !prev)}
      >
        {route.title}
        <CaretIcon
          iconWidth="24"
          direction="down"
          color="darkGreen"
          strokeWidth={2.2}
          className={clsx(styles.caret, isOpened && styles.rotate)}
        />
      </Text>
      <div
        className={clsx(styles.submenu, isOpened && styles.animate)}
        style={{ height: `${isOpened ? submenuHeight : 0}px` }}
      >
        {route.childrenItems.map(r => (
          <Link
            key={r.title}
            color="darkGreen"
            variant="nav"
            aria-label={`Navigate to ${r.title}`}
            destination={r.path}
            className="mb-xxs"
            onClick={handleNavigate}
            forceHardNavigation={true}
          >
            {r.title}
          </Link>
        ))}
      </div>
    </div>
  );
};
